<template>
  <div class="cidian">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>活动服务</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="flex between seach">
      <div>
        <div>活动状态</div>
        <el-radio-group v-model="sizeForm.activityStatus" @change="headleType">
          <el-radio-button v-for="item in  statusData" :key="item.label" :label="item.label"></el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <div>活动日期</div>
        <el-radio-group v-model="sizeForm.activityDate" @change="headleDate">
          <el-radio-button v-for="item in  timeData" :key="item.label" :label="item.label"></el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <div>搜索活动</div>
        <el-input type="text" v-model="sizeForm.keyword" placeholder="请输入活动名称" suffix-icon="el-icon-search"></el-input>
      </div>
    </div>
    <div class="activity-list">
      <activityList v-if="activityList.length > 0" :pageListTotal="pageListTotal" :pageSize="pageSize" :currentPage="currentPage" :activityList="activityList" @change="handleChange($event)"></activityList>
      <div class="none-data" v-else>
        <img src="@/assets/pc/images/personal/none.png" alt="" />
        <span style="font-size: 14px; color: #b0b0b0">暂无内容</span>
      </div>
    </div>
  </div>
</template>

<script>
import activityList from "./components/activityList";
import { request } from "@/network";
import { getMemberId } from "@/assets/public/utils/token";
export default {
  components: {
    activityList,
  },
  data() {
    return {
      sizeForm: {
        activityStatus: "全部",
        activityDate: "最新",
        keyword: "",
      },
      statusData:[{label:'全部'},{label:'进行中'},{label:'已结束'}],
      timeData:[{label:'最新'},{label:'1-3月'},{label:'4-6月'},{label:'7-9月'},{label:'10-12月'}],
      pageListTotal: 0,
      pageSize: 9,
      currentPage: 1,
      activityList: [],
    };
  },
  watch: {
    "sizeForm.keyword"(value) {
      this.getActivityList();
    },
  },
  async mounted() {
    this.getActivityList();
  },
  methods: {
    dataToDetail(clickId) {
      this.$router.push({ name: "activityDetails", query: { clickId } });
    },
    headleType: function (val) {
      this.currentPage =1
      this.getActivityList();
    },
    headleDate: function (val) {
      this.currentPage =1
      this.getActivityList();
    },
    async getActivityList() {
      const data = {
        memberId: getMemberId(),
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        activityDate: 0,
      };
      if (this.sizeForm.activityDate !== "最新") {
        data.activityDate =
          this.sizeForm.activityDate === "1-3月"
            ? 1
            : this.sizeForm.activityDate === "4-6月"
              ? 2
              : this.sizeForm.activityDate === "7-9月"
                ? 3
                : 4;
      }
      if (this.sizeForm.activityStatus !== "全部") {
        data.activityStatus = this.sizeForm.activityStatus === "进行中" ? 1 : 0;
      }
      if (this.sizeForm.keyword) {
        data.keyword = this.sizeForm.keyword
      }
      const { data: res } = await request(
        {
          method: "post",
          url: "/pcp/activity/activityList",
          data,
        },
        true
      );
      if (res.code !== 200) return this.$message.error("获取数据异常");
      this.activityList = res.data.list;
      this.pageListTotal = res.data.total;
    },
    async handleChange(value) {
      console.log(value)
      this.currentPage = value
      this.getActivityList();
    }
  },
};
</script>

<style lang="less" scoped>
.cidian {
  background-color: #fff;
  padding: 43px 0px;
  .seach {
    margin: 30px 0;
    > div {
      > div {
        margin-bottom: 10px;
      }
    }
    > div:nth-of-type(3) {
      width: 300px;
    }
  }
}
.none-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 340px;
}
/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #156ed0;
  border-color: #156ed0;
}
</style>